<template>
  <section>
    <b-row class="justify-content-between" align-v="start">
      <b-col md="5">
        <b-card>
          <b-form-group label="Select Court" label-for="court">
            <v-select
              v-model="currentCourt"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="courtOptions"
              :selectable="(option) => !option.value.includes('Supreme Court')"
              label="text"
              placeholder="Select Court"
              @input="onCourtSelect"
            />
          </b-form-group>
          <p v-if="defaultCourt">Default Court: {{ defaultCourt.text }}</p>
          <b-row class="justify-content-between px-1" align-v="center">
            <b-form-checkbox
              :disabled="!currentCourt"
              v-model="isSelected"
              class="custom-control-primary"
              @change="onDefaultSelect"
            >
              Mark Default
            </b-form-checkbox>
            <b-button
              :disabled="!currentCourt"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-sm"
              @click="refreshDisplayBoard"
            >
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span class="align-middle">Refresh</span>
            </b-button>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="7">
        <b-overlay
          :show="$store.state.displayboardStore.displayBoardLoading"
          rounded="sm"
          variant="transparent"
          opacity="0.5"
          blur="2px"
        >
          <b-card
            v-if="$store.state.displayboardStore.displayBoardData.length"
            no-body
          >
            <b-table
              responsive="sm"
              :items="$store.state.displayboardStore.displayBoardData"
              :fields="$store.state.displayboardStore.tableFields"
            >
              <template
                #cell(Court)="data"
                v-if="currentCourt.cslug == 'hc-punjab'"
              >
                <section>
                  <b-button
                    variant="link"
                    @click="
                      openCasesModel(`display-cases-${data.value}`, data.value)
                    "
                    >{{ data.value }}</b-button
                  >
                  <!-- modal Scrolling Content inside Modal-->
                  <b-modal
                    :id="`display-cases-${data.value}`"
                    scrollable
                    :title="`Cases`"
                    cancel-title="Close"
                    cancel-variant="outline-secondary"
                  >
                    <b-overlay
                      :show="
                        $store.state.displayboardStore.displayBoardCasesLoading
                      "
                      rounded="sm"
                      variant="transparent"
                      opacity="0.5"
                      blur="2px"
                    ></b-overlay>
                    <b-card-text
                      v-if="
                        $store.state.displayboardStore.displayBoardCasesData
                          .length &&
                        !$store.state.displayboardStore.displayBoardCasesLoading
                      "
                    >
                      <b-card-text
                        v-for="(content, index) in $store.state
                          .displayboardStore.displayBoardCasesData"
                        :key="index"
                      >
                        <b-row>
                          <b-col class="col-12">
                            <b-row class="justify-content-between">
                              <b-col class="mr-auto">
                                <h5
                                  class="font-weight-bold"
                                  v-if="
                                    Object.keys(content).includes('case_number')
                                  "
                                >
                                  {{ content.case_number }}
                                </h5>
                                <h6
                                  class="font-weight-bold"
                                  v-if="
                                    Object.keys(content).includes('party_name')
                                  "
                                >
                                  {{ content.party_name }}
                                </h6>
                              </b-col>
                            </b-row>
                            <div
                              v-if="
                                Object.keys(content).includes('current_status')
                              "
                            >
                              <small
                                class="text-secondary font-italic"
                                v-if="
                                  Object.keys(content).includes(
                                    'current_status'
                                  )
                                "
                                >Status: {{ content.current_status }}</small
                              >
                            </div>
                            <p
                              class="text-secondary p-0 m-0"
                              v-if="Object.keys(content).includes('judges')"
                            >
                              Judges: [ {{ content.judges.join(', ') }} ]
                            </p>
                            <p
                              class="text-secondary"
                              v-if="
                                Object.keys(content).includes('serial_number')
                              "
                            >
                              Sr. No. : {{ content.serial_number }}
                            </p>
                          </b-col>
                        </b-row>
                        <hr class="p-0 m-0" />
                      </b-card-text>
                    </b-card-text>
                    <b-card-text v-else class="m-5"> </b-card-text>
                  </b-modal>
                </section>
              </template>
            </b-table>
          </b-card>
          <b-card
            v-else-if="
              !$store.state.displayboardStore.displayBoardData.length &&
              !$store.state.displayboardStore.displayBoardLoading
            "
            class="text-center"
          >
            <p>Record Not Found!</p>
          </b-card>
          <b-card v-else class="text-center">
            <p></p>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormCheckbox,
  BInputGroupAppend,
  BCard,
  BCardText,
  BTable,
  BOverlay,
  BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BCard,
    BCardText,
    vSelect,
    BTable,
    BOverlay,
    BSpinner
  },
  directives: {
    Ripple
  },
  data() {
    return {
      currentCourt: null,
      courtOptions: [],
      isSelected: false,
      defaultCourt: null,
      interval: null
    }
  },
  beforeMount() {
    //   Court Setup
    this.$store.dispatch('courtStore/getAllCourts').then((response) => {
      console.log('Courts --> ', response)
      response.forEach((value, index) => {
        if (
          value.type == 'sci' ||
          (value.type == 'hc' && !value.uniqueId.includes('mediation'))
        ) {
          this.courtOptions.push(value)
        }
      })
      console.log(this.courtOptions)
    })

    let userData = JSON.parse(localStorage.getItem('userData'))
    let cSelected = null
    if (Object.keys(userData).includes('default_court')) {
      if (userData.default_court) {
        cSelected = userData.default_court
        this.currentCourt = {
          value: cSelected.value,
          text: cSelected.label,
          cslug: cSelected.cslug
        }
        this.defaultCourt = {
          value: cSelected.value,
          text: cSelected.label,
          cslug: cSelected.cslug
        }
        ;(this.isSelected = cSelected !== null ? true : false),
          this.refreshDisplayBoard()
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    openCasesModel(id, court) {
      console.log(court)
      this.$store.dispatch('displayboardStore/fetchDisplayCasesBoard', {
        value: this.currentCourt.value,
        payload: {
          court_number: court
        }
      }),
        this.$bvModal.show(id)
    },

    startInterval() {
      const beforeTime = moment('4:59 PM', 'LT').format('HH')
      const afterTime = moment('10:00 AM', 'LT').format('HH')
      const now = moment().format('HH')
      const day = moment().format('dddd')
      if (day !== 'Sunday') {
        if (now > afterTime && now < beforeTime) {
          clearInterval(this.interval)
          this.interval = setInterval(
            () =>
              this.$store.dispatch('displayboardStore/fetchDisplayBoard', {
                value: this.currentCourt.value
              }),
            60000
          )
        }
      }
    },
    refreshDisplayBoard() {
      this.$store.dispatch('displayboardStore/fetchDisplayBoard', {
        value: this.currentCourt.value
      })
      this.startInterval()
    },
    onCourtSelect(value) {
      console.log('Value', this.defaultCourt)
      if (value) {
        if (this.defaultCourt) {
          console.log('Default Selected', this.defaultCourt.value, value.value)
          if (this.defaultCourt.value === value.value) {
            this.isSelected = true
          } else {
            this.isSelected = false
          }
        }
        this.startInterval()
        this.$store.dispatch('displayboardStore/fetchDisplayBoard', {
          value: value.value
        })
      }
    },
    onDefaultSelect(val) {
      let payload = {
        default_court: null
      }
      console.log('val --> ', this.defaultCourt, this.currentCourt)
      if (val === true) {
        this.defaultCourt = this.currentCourt
        payload = {
          default_court: {
            value: this.currentCourt.value,
            label: this.currentCourt.text,
            cslug: this.currentCourt.cslug
          }
        }
      } else {
        this.defaultCourt = null
        payload = {
          default_court: null
        }
      }
      this.$store.dispatch('settingStore/updateUser', {
        ctx: this,
        payload
      })
    }
  }
}
</script>
